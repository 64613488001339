.loading-overlay {
    height: 100vh;
    width: 100vw;
    background: #0050B7;
    position: absolute;
    z-index: 10000001;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation-name: loading-overlay-fadein;
    animation-duration: 300ms;
}

@keyframes loading-overlay-fadein {
    from { 
        opacity: 0; 
    }
    to { 
        opacity: 1; 
    }
  }
