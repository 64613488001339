@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
  font-family: 'Inter';
}

.auth0-lock.auth0-lock .auth0-lock-content-wrapper {
  flex-grow: unset !important;
}