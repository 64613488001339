canvas {
  height: auto !important;
  width: auto !important;
  min-width: 250px;
}

@media (max-width: 575.98px) {
  canvas {
    height: auto !important;
    width: auto !important;
    max-width: 200px;
  }
}